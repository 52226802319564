* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(45deg, #ffffff, #dcf4ff);
}
.container {
  position: relative;
  width: 70vw;
  height: 80vh;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #4b98fc, #afcffa);
  z-index: 6;
  transform: translateX(100%);
  transition: 1s ease-in-out;
}
.signin-signup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 5;
}
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  min-width: 238px;
  padding: 0 10px;
}
form.sign-in-form {
  opacity: 1;
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}
form.sign-up-form {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}
.title {
  font-size: 35px;
  color: #07286e;
  margin-bottom: 10px;
}
.input-field {
  width: 100%;
  height: 50px;
  background: #f0f0f0;
  margin: 10px 0;
  border: 2px solid #07286e;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.input-field i {
  flex: 1;
  text-align: center;
  color: #666;
  font-size: 18px;
}

.input-field .eye {
  cursor: pointer;
}

.input-field input {
  flex: 5;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #444;
}
.operator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 10px 0;
  color: #666;
  cursor: pointer;
}
.operator .remember {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.operator .remember label {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.operator .remember .passs,
.operator .passs {
  color: #07286e;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.btn {
  width: 350px;
  height: 50px;
  border: none;
  border-radius: 8px;
  background: #07286e;
  color: #fff;
  font-weight: 600;
  margin: 10px 0;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid black;
}
.btn:hover {
  background: #1d1c58;
}
.social-text {
  margin: 10px 0;
  font-size: 16px;
}
.social-media {
  display: flex;
  justify-content: center;
}
.social-icon {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444;
  border: 1px solid #444;
  border-radius: 50px;
  margin: 0 5px;
}
a {
  text-decoration: none;
}
.social-icon:hover {
  color: #07286e;
  border-color: #07286e;
}
.panels-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  min-width: 238px;
  padding: 0 10px;
  text-align: center;
  z-index: 6;
}
.left-panel {
  pointer-events: none;
}
.content {
  color: #000;
  transition: 1.1s ease-in-out;
  transition-delay: 0.5s;
}
.panel h3 {
  font-size: 24px;
  font-weight: 600;
}
.panel p {
  font-size: 15px;
  padding: 10px 0;
}
.image {
  width: 100%;
  transition: 1.1s ease-in-out;
  transition-delay: 0.4s;
}
.left-panel .image,
.left-panel .content {
  transform: translateX(-200%);
}
.right-panel .image,
.right-panel .content {
  transform: translateX(0);
}
.account-text {
  display: none;
}
.container.sign-up-mode::before {
  transform: translateX(0);
}
.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(200%);
}
.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(0);
}
.container.sign-up-mode form.sign-in-form {
  opacity: 0;
}
.container.sign-up-mode form.sign-up-form {
  opacity: 1;
}
.container.sign-up-mode .right-panel {
  pointer-events: none;
}
.container.sign-up-mode .left-panel {
  pointer-events: all;
}

@media (max-width: 1100px) {
  .container {
    width: 100vw;
    height: 100vh;
  }
}
@media (max-width: 1100px) {
  .container::before {
    display: none;
  }
  form {
    width: 80%;
  }
  form.sign-up-form {
    display: none;
  }
  .container.sign-up-mode2 form.sign-up-form {
    display: flex;
    opacity: 1;
  }
  .container.sign-up-mode2 form.sign-in-form {
    display: none;
  }
  .panels-container {
    display: none;
  }
  .account-text {
    display: initial;
    margin-top: 30px;
    display: flex;
    gap: 1rem;
  }
}
@media (max-width: 420px) {
  form {
    width: 100%;
  }
}
